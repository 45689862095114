import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import type { RocketEvent } from "@/game-rocket/models/events.mjs";
import { RocketValidator } from "@/game-rocket/models/events.mjs";
import {
  EVENT_ROCKET_LOAD,
  EVENT_ROCKET_MATCH_END,
  EVENT_ROCKET_RUNNING,
} from "@/game-rocket/utils/rocket-client-api.mjs";
import { devDebug } from "@/util/dev.mjs";

async function initRocketLeague() {
  await initEvents;

  handleMessage(EVENTS.ROCKET_RUNNING, (isRunning: boolean) => {
    devDebug("Received ROCKET_RUNNING", isRunning);
    eventBus.emit(EVENT_ROCKET_RUNNING, isRunning);
  });

  handleMessage(EVENTS.ROCKET_LOAD, (data: RocketEvent) => {
    devDebug("Received ROCKET_LOAD", data);
    const parsed = RocketValidator(data);
    eventBus.emit(EVENT_ROCKET_LOAD, parsed);
  });

  handleMessage(EVENTS.ROCKET_MATCH_END, (data: RocketEvent) => {
    devDebug("Received ROCKET_MATCH_END", data);
    const parsed = RocketValidator(data);
    eventBus.emit(EVENT_ROCKET_MATCH_END, parsed);
  });
}

export default initRocketLeague;
