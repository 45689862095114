import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, {
  Optional,
  Silent,
  Union,
  Unknown,
} from "@/__main__/data-model.mjs";

const AllSaveObjectKeys = Union([
  "MusicPlayerSave_TA",
  "SoundSettingsSave_TA",
  "UISavedValues_TA",
  "MapPrefsSave_TA",
  "RankedReconnectSave_TA",
  "NetworkSave_TA",
  "ConnectionQualitySave_TA",
  "TutorialSave_TA",
  "BlogTileCache_TA",
  "ShopCatalogueCacheSave_TA",
  "ProductsSave_TA",
  "PlaylistSkillDataSave_TA",
  "MatchmakingSettingsSave_TA",
  "SeasonSave_TA",
  "VideoSettingsSavePC_TA",
  "GameplaySettingsSave_TA",
  "ProductsFavoriteSave_TA",
  "ExhibitionMatchSettingsSave_TA",
  "PrivateMatchSettingsSave_TA",
  "ServerBrowserSettingsSave_TA",
  "NotificationSave_TA",
  "ContentSave_TA",
  "PlayerLegacyStatusSave_TA",
  "BanSave_TA",
  "TrainingProgressSave_TA",
  "MultiItemDropsSave_TA",
  "PlayMenuSave_TA",
  "TournamentSettingsSave_TA",
  "EulaSave_TA",
  "PrivacyPolicySave_TA",
  "EpicAccountSave_TA",
  "EOSVoiceSettingsSave_TA",
  "EOSGameClipsSettingsSave_TA",
]);

const PlayerSaveObjectKeys = Union([
  "ProfileGameplaySave_TA",
  "PlayerBannerSave_TA",
  "ProfileControlsSave_TA",
  "ProfileCameraSave_TA",
  "ProfileQuickChatSave_TA",
  "ProfileLoadoutSave_TA",
  "ProfileGamepadSave_TA",
  "PersonaSave_TA",
  "ProductsArchiveSave_TA",
  "PlayerAvatarBorderSave_TA",
  "ProfilePCSave_TA",
  "FirstTimeExperienceSave_TA",
  "CrumbTrailSave_TA",
]);

const StatValueIds = Union([
  "Win",
  "Loss",
  "TimePlayed",
  "Shot",
  "Assist",
  "Center",
  "Clear",
  "PoolShot",
  "Goal",
  "AerialGoal",
  "BicycleGoal",
  "BulletGoal",
  "BackwardsGoal",
  "LongGoal",
  "OvertimeGoal",
  "TurtleGoal",
  "AerialHit",
  "BicycleHit",
  "BulletHit",
  "JuggleHit",
  "FirstTouch",
  "BallHit",
  "Save",
  "EpicSave",
  "FreezeSave",
  "HatTrick",
  "Savior",
  "Playmaker",
  "MVP",
  "FastestGoal",
  "SlowestGoal",
  "FurthestGoal",
  "OwnGoal",
  "MostBallTouches",
  "FewestBallTouches",
  "MostBoostPickups",
  "FewestBoostPickups",
  "BoostPickups",
  "CarTouches",
  "Demolition",
  "Demolish",
  "LowFive",
  "HighFive",
]);

const Player = {
  EpicAccountId: String,
  NpId: {
    Handle: {
      Data: [Number],
      Dummy: [Number],
      Term: Number,
    },
    Opt: Number,
    Reserved: Number,
  },
  Platform: Number,
  SplitscreenID: Number,
  Uid: Number,
} satisfies ModelDefinition;

const OnlinePlayer = {
  CachedEpicID: String,
  CleanPlayerID: Player,
  PlayerID: Player,
  PlayerName: String,
} satisfies ModelDefinition;

const AchievementSave = {
  ClassName: Union(["AchievementSave_TA"]),
  Data: {
    BreakoutGoals: Number,
    BreakoutPlatformsDamaged: Number,
    ChampionshipsWon: Number,
    CompletedMatchesWithClubmates: Number,
    CreditsViewed: Number,
    ExhibitionMatchesPlayed: Number,
    GameEventsPlayed: Number,
    GameEventsWon: Number,
    GamesWonInARow: Number,
    GoalSaves: Number,
    GoalShots: Number,
    GoalShotsAny: Number,
    GoalsOrAssists: Number,
    HighestMVPScore: Number,
    HighestRecordedCertifiedRank: Number,
    NewOfflineProductsUnlocked: Number,
    PrivateMatchesPlayed: Number,
    RandomItemsDropped: Number,
    RankedMatchesPlayed: Number,
    RegularSeasonsCompleted: Number,
    ReplayEditorOpened: Number,
    SavageGoals: Number,
    SpectacularGoals: Number,
    TotalBoostTime: Number,
    TotalDriveDistanceKM: Number,
    TotalScoredGoals: Number,
    TotalShotsBlocked: Number,
    TotalTimeOnWall: Number,
    UnrankedMatchesPlayed: Number,
  },
} satisfies ModelDefinition;

const PlayerSaveObjects = [
  Union([
    { ClassName: PlayerSaveObjectKeys },
    {
      ClassName: Union(["ProfileStatsSave_TA"]),
      Data: {
        StatValues: [
          {
            Id: StatValueIds,
            Value_Private: Number,
            Value_Ranked: Number,
            Value_Unranked: Number,
          },
        ],
      },
    },
    AchievementSave,
  ]),
] satisfies ModelDefinition;

const MatchEvent = {
  NumBots: Number,
  PRIs: [
    {
      MatchAssists: Number,
      MatchBonusXP: Number,
      MatchBreakoutDamage: Number,
      MatchDemolishes: Number,
      MatchGoals: Number,
      MatchOwnGoals: Number,
      MatchSaves: Number,
      MatchScore: Number,
      MatchShots: Number,
      MatchStats: [
        {
          Count: Number,
          StatEvent: {
            Description: String,
            Label: String,
            PluralLabel: String,
            Points: Number,
          },
        },
      ],
      PawnType: Union(["PT_Player"]),
      PlayerName: String,
      ProductStats: [
        {
          Description: String,
          Label: String,
          Value: Number,
        },
      ],
      TotalGameTimePlayed: Number,
      UniqueId: Player,
    },
  ],
  Teams: [
    {
      ClubID: Number,
      CustomTeamName: String,
      FormerMembers: [Unknown],
      Members: [Unknown],
      Score: Number,
      Size: Number,
      TeamName: String,
    },
  ],
} satisfies ModelDefinition;

const RocketLoad = {
  match_event: Optional(MatchEvent),
  online_player: OnlinePlayer,
  persona_club_data: Silent(Unknown),
  save_game_manager: {
    Player: {
      OnlinePlayer: OnlinePlayer,
      Profile: {
        ProfileName: String,
        SaveObjects: PlayerSaveObjects,
      },
    },
    SaveData: {
      Profiles: [
        {
          ProfileName: String,
          SaveObjects: PlayerSaveObjects,
        },
      ],
      SaveObjects: [
        Union([
          { ClassName: AllSaveObjectKeys },
          {
            ClassName: Union(["ClientXPSave_TA"]),
            Data: {
              CurrentLevelXPThreshold: Number,
              Level: Number,
              NextLevelXPThreshold: Number,
              TotalXP: Number,
            },
          },
          AchievementSave,
        ]),
      ],
    },
  },
} satisfies ModelDefinition;

export const RocketValidator = createModel(RocketLoad);

export type RocketEvent = DeepReadonly<FromModel<typeof RocketValidator>>;
